const categories = [
  "Kitchen",
  "Cleaning",
  "Electronics",
  "Self-care",
  "Clothing",
  "Miscellaneous",
];

export default categories;
